import React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Image from "@escolalms/sdk/lib/react/components/Image";
import { API } from "@escolalms/sdk/lib";
import CourseDuration from "./CourseDuration";
import "./index.scss";

export const CourseCard: React.FC<{
  course: API.Course;
  isEndedVariant?: boolean;
  finishDate?: Date;
  minHeight?: boolean;
  location?: string;
}> = ({
  course,
  isEndedVariant = false,
  finishDate,
  minHeight,
  location = "rodzice",
}) => {
  return (
    <div className={`single-courses-box ${minHeight && "with-min-height"}`}>
      <div className="courses-image">
        {course.image_path && (
          <Link
            to={`/${isEndedVariant ? "kurs" : location}/${course.id}`}
            className="d-block image"
          >
            <Image path={course.image_path} srcSizes={[380, 380 * 2]} />
          </Link>
        )}
      </div>
      <div className="courses-content">
        {course.categories && course.categories?.length > 0 && (
          <div className="courses-categories">
            {course.categories.map((category) => (
              <span key={typeof category === "object" ? category.id : category}>
                {typeof category === "object" ? category.name : category}
              </span>
            ))}
          </div>
        )}

        <h3>
          <Link to={`/${isEndedVariant ? "kurs" : location}/${course.id}`}>
            {course.title}
          </Link>
        </h3>
        {!isEndedVariant && course.duration && (
          <CourseDuration duration={course.duration} />
        )}
        {isEndedVariant && finishDate && (
          <span className="course-finish-date">
            <i className="bx bx-check"></i>
            {format(new Date(finishDate), "dd-MM-yyyy")}
          </span>
        )}
      </div>
    </div>
  );
};

export default CourseCard;
