import React, { useContext, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Logo from "../../images/awf/logoawf.svg";
import LogoM from "../../images/awf/logom.svg";
import UpdateProfileModal from "../UpdateProfileModal";
import UserProfileLogo from "../../images/awf/user-profilo.svg";
import { CaretDownOutlined } from "@ant-design/icons";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import LoginModal from "../LoginModal";
import routes from "../Routes/routes";

import "./Navbar.scss";
import { API } from "@escolalms/sdk/lib";

export const UserNavbarItem: React.FC<{
  user?: API.UserItem;
  logout: () => void;
  toggleNavbar: () => void;
}> = ({ user, logout, toggleNavbar }) => {
  const history = useHistory();

  return (
    <div className="option-item">
      {user ? (
        <React.Fragment>
          <div className="option-item__icon">
            <img src={UserProfileLogo} alt="user" />
          </div>
          <div className="user-dropdown">
            <Link to="/" onClick={(e) => e.preventDefault()}>
              {user.first_name} {user.last_name} <span></span>{" "}
              <CaretDownOutlined />
            </Link>

            <ul className="dropdown-menu">
              <li className="nav-item">
                <UpdateProfileModal />
              </li>
              <li className="nav-item">
                <Link
                  to="/"
                  className="nav-link"
                  onClick={(e) => {
                    e.preventDefault();
                    logout && logout();
                  }}
                >
                  Wyloguj się
                </Link>
              </li>
            </ul>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <button className="default-btn-style-two">
            Zaloguj <span></span>
          </button> */}
          <LoginModal />

          {/* <Link to={routes.authentication} className="default-btn"> */}
          <button
            className="app-button app-button-dark app-button-long app-button--register"
            onClick={() => history.push(routes.authentication)}
          >
            Zarejestruj <span className="rollover"></span>
          </button>
        </React.Fragment>
      )}
    </div>
  );
};

const SubCategories = ({ data }: any) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="category-dropdown">
      <ul>
        <li onClick={() => setOpen(!open)}>^</li>
        {open &&
          data &&
          data.map((item: any) => (
            <li>
              {item.label}{" "}
              {!!item?.sub?.length && <SubCategories data={item.sub} />}
            </li>
          ))}
      </ul>
    </div>
  );
};

const Navbar = () => {
  const [menu, setMenu] = React.useState(true);
  const { user: userObj, logout } = useContext(EscolaLMSContext);

  const user = userObj.value;

  const toggleNavbar = () => {
    setMenu(!menu);
  };

  const classOne = menu
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <React.Fragment>
      <div id="navbar" className="navbar-area">
        <div className="escolalms-nav">
          <div className="container-fluid">
            <div className="navbar navbar-expand-lg navbar-light">
              <Link to="/" onClick={toggleNavbar} className="navbar-brand">
                <img src={Logo} alt="logo" />
                <img src={LogoM} alt="logom" />
              </Link>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  {/* <li className="nav-item ">
                    <NavLink
                      className="nav-link"
                      to={`${routes.courses}?free=true&per_page=12`}
                    >
                      Materiały szkoleniowe
                    </NavLink>
                  </li> */}
                  <li className="nav-item ">
                    <NavLink
                      className="nav-link"
                      to={`${routes.fus}?category_id=19&per_page=12`}
                    >
                      FUS
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      className="nav-link"
                      to={`${routes.parents}?free=true&per_page=12`}
                    >
                      Materiały dla rodziców
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      className="nav-link"
                      to={`${routes.courses}?klasy=all&per_page=12&tag%5B%5D=Warsztaty`}
                    >
                      Materiały dla nauczycieli
                    </NavLink>
                    {user && (
                      <div className="option-item">
                        <ul className="dropdown-menu courses">
                          <li className="nav-item courses">
                            <NavLink
                              to={`${routes.courses}?klasy=low&per_page=12&tag%5B%5D=Klasy%201-3`}
                              className="nav-link"
                            >
                              Klasy (1-3)
                            </NavLink>

                            {/* <SubCategories data={lowAge} /> */}
                          </li>
                          <li className="nav-item courses">
                            <NavLink
                              to={`${routes.courses}?klasy=mid&per_page=12&tag%5B%5D=Klasy%204-8`}
                              className="nav-link"
                            >
                              Klasy (4-8)
                            </NavLink>{" "}
                            {/* <SubCategories data={lowAge} /> */}
                          </li>
                          <li className="nav-item courses">
                            <NavLink
                              to={`${routes.courses}?klasy=high&per_page=12&tag%5B%5D=Klasy%208%2B`}
                              className="nav-link"
                            >
                              Klasy (8+)
                            </NavLink>{" "}
                            {/* <SubCategories data={lowAge} /> */}
                          </li>
                        </ul>
                      </div>
                    )}
                  </li>
                  {user && (
                    <li className="nav-item">
                      <NavLink className="nav-link" to={routes.myCourses}>
                        Moje kursy
                      </NavLink>
                    </li>
                  )}

                  {/* <li className="nav-item ">
                    <NavLink className="nav-link" to={routes.tutors}>
                      Autorzy
                    </NavLink>
                  </li> */}
                  {/* <li className="nav-item">
                    <NavLink className="nav-link" to={routes.faq}>
                      FAQ
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to={routes.contact}>
                      Kontakt
                    </NavLink>
                  </li> */}
                </ul>

                <div className="others-option d-flex align-items-center">
                  <UserNavbarItem
                    user={user}
                    toggleNavbar={toggleNavbar}
                    logout={logout}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
