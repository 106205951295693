import React, { FC } from "react";
import { Field } from "formik";
import "./index.scss";

interface Props {
  type?: string;
  label: string;
  name: string;
  errors?: any;
  touched?: any;
  className?: string;
  as?: string;
  children?: any;
  isFormik?: boolean;
  ref?: React.MutableRefObject<HTMLInputElement>;
}

const Input: FC<Props> = ({
  type,
  label,
  name,
  errors,
  touched,
  className,
  as,
  children,
  isFormik = false,
  ref,
}) => {
  return (
    <>
      <label htmlFor={name}>{label}</label>
      {isFormik ? (
        <Field
          as={as || "input"}
          name={name}
          type={type || "text"}
          className={`app-input ${errors && touched ? "is-invalid" : ""} ${
            className && className
          }`}
        >
          {children && children}
        </Field>
      ) : (
        <input
          ref={ref}
          name={name}
          type={type || "text"}
          className={`app-input ${className && className}`}
        />
      )}
    </>
  );
};

export default Input;
