import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

function useWordCut<T>(collection: T[], objectKey: string, cutWords: string[]) {
  const [cutCollection, setCutCollection] = useState(collection);
  const history = useHistory();

  useEffect(() => {
    if (history.location.search.includes("klasy")) {
      const removeClassTags = cutCollection?.filter(
        // TODO FIXME
        // @ts-ignore
        (collectionItem: Record<string, any>) =>
          !cutWords.includes(collectionItem[objectKey])
      );
      setCutCollection(removeClassTags);
    } else {
      setCutCollection(collection);
    }
  }, [history.location.search, objectKey]);

  return cutCollection;
}

export default useWordCut;
