import Layout from "../components/_App/Layout";
import React from "react";
import { Link } from "react-router-dom";
import ErrorBox from "../components/ErrorBox/ErrorBox";

const Custom404 = () => {
  return (
    <Layout>
      <ErrorBox title="Błąd 404 : Strona nie istnieje" className="center-text error-box--with-big-padding">
        <p className="mtb-50">Strona, której szukasz mogła zostać usunięta, jej nazwa została zmieniona lub jest tymczasowo niedostępna.</p>

        <div className="btn-box">
          <Link to="/" className="default-btn">
            <i className="flaticon-home" /> wróć do strony głównej
          </Link>
        </div>
      </ErrorBox>
    </Layout>
  );
};

export default Custom404;
