import React from "react";
import { API } from "@escolalms/sdk/lib";
import WhiteWrapper from "../_App/WhiteWrapper";
import CourseCard from "../CourseCard";

const FinishedCourses: React.FC<{
  finishedCourses?: API.CourseProgressItem[];
}> = ({finishedCourses = []}) => {
  return (
    <React.Fragment>
      {finishedCourses.length > 0 && (
        <div className="ended-courses">
          <WhiteWrapper className="">
            <h2>Ukończone kursy</h2>
            <div className="row">
              {finishedCourses.map((item: API.CourseProgressItem) => (
                <div
                  className="col-lg-4 col-md-12"
                  key={item.course.id}
                >
                  <CourseCard
                    course={item.course}
                    isEndedVariant
                    finishDate={item.finish_date}
                  />
                </div>
              ))}
            </div>
          </WhiteWrapper>
        </div>
      )}
    </React.Fragment>
  );
};

export default FinishedCourses;
