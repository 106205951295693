import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import isMobile from "is-mobile";
import "./VideoPlayer.scss";

const VideoPlayer: React.FC<{
  topicUrl: string;
  onFinish: () => void;
}> = ({ topicUrl, onFinish }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const [key, setKey] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setVideoSrc(null);
    };
  }, []);

  useEffect(() => {
    setIsVideoPlaying(false);
    setIsFinished(false);
    setKey(key + 1);
    setVideoSrc(topicUrl);

    return () => {
      setVideoSrc(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicUrl]);

  if (isMobile() || true) {
    return (
      <div className="topic-video-wrapper-simple container-md">
        {videoSrc && <video src={videoSrc} preload="auto" controls autoPlay />}
      </div>
    );
  }

  return (
    <div className="topic-video-wrapper">
      {!isVideoPlaying && <div className="overlay" />}

      <ReactPlayer
        key={key}
        playing={isVideoPlaying}
        // @ts-ignore
        url={videoSrc}
        controls
        width="100%"
        height="80vh"
        onEnded={() => {
          onFinish();
          setIsFinished(true);
          setIsVideoPlaying(false);
        }}
        file={{
          forceVideo: true,
        }}
      />
      {!isVideoPlaying && (
        <button className="default-btn" onClick={() => setIsVideoPlaying(true)}>
          {isFinished ? "Obejrzyj jeszcze raz" : t("playVideo")}
        </button>
      )}
    </div>
  );
};

export default VideoPlayer;
