import React from "react";
import "./index.scss";

const Pagination: React.FC<{
  currentPage: number;
  total: number;
  perPage: number;
  onPage: (page: number) => void;
}> = ({ currentPage, total, perPage, onPage }) => {
  const lastPage = Math.ceil(total / perPage);
  return (
    <div className="col-lg-12 col-md-12 col-sm-12">
      <div className="pagination-area text-center">
        <span
          className="prev page-numbers"
          onClick={() => {
            onPage(currentPage <= 1 ? 1 : currentPage - 1);
          }}
        >
          <i className="bx bx-chevrons-left"></i>
        </span>
        {Array.from({ length: lastPage }).map((page, i) => (
          <span
            key={`#${i + 1}`}
            onClick={() => {
              onPage(i + 1);
            }}
            className={
              i + 1 === currentPage ? "page-numbers current" : "page-numbers"
            }
            aria-current="page"
          >
            {i + 1}
          </span>
        ))}
        <span
          className="next page-numbers"
          onClick={() => {
            onPage(currentPage === lastPage ? lastPage : currentPage + 1);
          }}
        >
          <i className="bx bx-chevrons-right"></i>
        </span>
      </div>
    </div>
  );
};

export default Pagination;
