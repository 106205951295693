import React, { useContext, useEffect } from "react";

import PageBanner from "../../../components/Common/PageBanner";

import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { useParams } from "react-router-dom";
import Image from "@escolalms/sdk/lib/react/components/Image";
import CourseCard from "../../../components/CourseCard";
import Layout from "../../../components/_App/Layout";
import ReactMarkdownReader from "../../../components/ReactMarkdown/ReactMarkdownReader";
import { API } from "@escolalms/sdk/lib";

const Profile = () => {
  const { id } = useParams<{ id: string }>();

  const { tutor, fetchTutor, courses, fetchCourses } =
    useContext(EscolaLMSContext);

  useEffect(() => {
    if (id) {
      fetchTutor(Number(id));
      fetchCourses({ author_id: Number(id) });
    }
  }, [id, fetchTutor, fetchCourses]);

  return (
    <Layout>
      <React.Fragment>
        {/* <Navbar /> */}
        <PageBanner
          pageTitle="Tutor"
          homePageUrl="/"
          homePageText="Home"
          activePageText="Tutor"
        />

        <div className="profile-area">
          <div className="container">
            {tutor.value && (
              <div className="profile-box ptb-100">
                <div className="row align-items-center">
                  <div className="col-lg-4 col-md-4">
                    <div className="image">
                      {tutor.value.path_avatar && (
                        <Image
                          path={tutor.value.path_avatar}
                          srcSizes={[355, 355 * 2]}
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-lg-8 col-md-8">
                    <div className="content">
                      <h3>
                        {tutor.value.first_name} {tutor.value.last_name}
                      </h3>
                      <span className="sub-title">Tutor</span>
                      <div>
                        {tutor.value.bio && (
                          <ReactMarkdownReader>
                            {tutor.value.bio}
                          </ReactMarkdownReader>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="profile-courses pb-70">
              <h3 className="title">Tutor Courses</h3>
              <div className="row">
                {courses.list?.data.map((course: API.CourseListItem) => (
                  <div className="col-lg-4 col-md-6" key={course.id}>
                    <CourseCard course={course} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </Layout>
  );
};

export default Profile;
