import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { useTranslation } from "react-i18next";

import TagCloud from "../Common/TagCloud";
import useWordCut from "../../hooks/useWordCut";

import "./CoursesSidebar.scss";

const CategoryTreeOptions: React.FC<{
  categories: API.Category[];
  nest?: number;
  id?: number;
}> = ({ categories, nest = 0, id }) => {
  const filteredCats = useWordCut<API.Category>(categories, "name", [
    "Klasy 1-3",
    "Klasy 4-8",
    "Klasy 8+",
    "Dla rodziców",
  ]);

  return (
    <React.Fragment>
      {filteredCats
        .filter((category: any) => category.count > 0)
        .map((category) => {
          return (
            <React.Fragment key={category.id}>
              <option value={category.id}>
                {Array.from({ length: nest + 1 }).join(" > ")}
                {category.name}
              </option>
              {category &&
                category.subcategories &&
                category.subcategories.length > 0 && (
                  <CategoryTreeOptions
                    categories={category.subcategories}
                    nest={nest + 1}
                    id={id}
                  />
                )}
            </React.Fragment>
          );
        })}
    </React.Fragment>
  );
};

export const CategoryTree: React.FC<{
  onChange: (value: string) => void;
  id?: number;
}> = ({ onChange, id = -1 }) => {
  const { t } = useTranslation();

  const { categoryTree } = useContext(EscolaLMSContext);
  if (!categoryTree?.list) {
    return <pre>loading...</pre>;
  }
  return (
    <div className="select-box">
      <select
        value={id}
        className="app-input"
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
        suppressHydrationWarning={true}
      >
        <option value="">{t("All Categories")}</option>
        <CategoryTreeOptions categories={categoryTree.list} id={id} />
      </select>
    </div>
  );
};

export const Tutors: React.FC<{
  onChange: (value: string) => void;
  id?: number;
}> = ({ onChange, id }) => {
  const { t } = useTranslation();

  const { tutors } = useContext(EscolaLMSContext);

  return (
    <div className="select-box">
      <select
        className="app-input"
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
        suppressHydrationWarning={true}
      >
        <option value="">{t("All Tutors")}</option>
        {tutors.list?.map((tutor: API.UserItem) => (
          <option key={tutor.id} value={tutor.id}>
            {tutor.first_name} {tutor.last_name}
          </option>
        ))}
      </select>
    </div>
  );
};

const hasTag = (tags: API.Tag[], tag: API.Tag) => {
  return tags.some((stag) => stag.title === tag.title);
};

const CoursesSidebar: React.FC<{
  onSearch: (value: string) => void;
  // i need this any to many changes for client
  onTag: (value: any) => void;
  onCategory: (value: string) => void;
  onTutor: (value: string) => void;
  multiple?: boolean;
  params?: API.CourseParams;
}> = ({ onSearch, onTag, onCategory, onTutor, multiple = false, params }) => {
  const { fetchTutors } = useContext(EscolaLMSContext);

  const [tags, setTags] = useState<API.Tag[]>([]);

  const toggleTag = useCallback(
    (tag: API.Tag) => {
      setTags((prevState) => {
        return hasTag(tags, tag)
          ? prevState.filter((stag) => stag.title !== tag.title)
          : [...prevState, tag];
      });
    },
    [tags, multiple]
  );

  useEffect(() => {
    onTag && onTag(tags);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, multiple]);

  useEffect(() => {
    fetchTutors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement | null>();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = params?.title ? params?.title : "";
    }
  }, [params]);

  return (
    <div className="widget-area">
      <div className="widget widget_search">
        <h2 className="widget-title">Szukaj materiałów szkoleniowych</h2>

        <form
          className="search-form"
          onSubmit={(e) => {
            onSearch && inputRef.current && onSearch(inputRef.current.value);
            e.preventDefault();
          }}
        >
          <label>
            <span className="screen-reader-text">{t("Search for")}:</span>
            <input
              // TODO wtf ?
              //@ts-ignore
              ref={inputRef}
              type="search"
              className="app-input app-input--icon"
              placeholder={t("Search")}
            />
          </label>
          <button type="submit">
            <i className="bx bx-search-alt" />
          </button>
        </form>
      </div>

      <div className="widget widget_search">
        <h3 className="widget-title small">{t("Categories")}</h3>

        <CategoryTree id={params?.category_id} onChange={onCategory} />
      </div>

      {/* <div className="widget widget_search">
        <h3 className="widget-title small">{t("Tutors")}</h3>

        <Tutors id={params?.author_id} onChange={onTutor} />
      </div> */}
      {/* show only for this category */}
      {Number(params?.category_id) === 3 && (
        <div className="widget widget_tag_cloud">
          <TagCloud onClick={toggleTag} activeTagTitle={params?.tag} />
        </div>
      )}
    </div>
  );
};

export default CoursesSidebar;
