import React, { PropsWithChildren } from "react";

import WhiteWrapper from "../_App/WhiteWrapper";
import "./index.scss";

const ErrorBox: React.FC<
  PropsWithChildren<{
    title?: string;
    className?: string;
  }>
> = ({ title = "Błąd", children, className = "" }) => {
  return (
    <React.Fragment>
      <div className={`container error-box mtb-100 ${className}`}>
        <div className="row error-box__wrapper">
          <WhiteWrapper className="error-box__content">
            <h4 className="error-box__title">{title}</h4>
            {children}
          </WhiteWrapper>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ErrorBox;
