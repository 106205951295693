import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useHistory, useParams } from "react-router-dom";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { API } from "@escolalms/sdk/lib";
import CourseProgramContent from "./CourseProgramContent";
import CourseProgramList from "./CourseProgramList";
import ArrowIcon from "../../icons/Arrow";
import ReactMarkdownReader from "../ReactMarkdown/ReactMarkdownReader";
import { fixContentForMarkdown } from "../../utils/markdown";
import "./CourseProgramLesson.scss";

export const courseIncomplete = 0;
export const courseComplete = 1;
export const courseInProgress = 2;

export const CourseProgramLessons: React.FC<{ program: API.CourseProgram }> = ({
  program,
}) => {
  const [isDisabledNextTopicButton, setIsDisabledNextTopicButton] =
    useState(false);

  const { push } = useHistory();
  const { lessonID, topicID } =
    useParams<{ lessonID: string; topicID: string }>();

  const lessonId = lessonID ? lessonID : program.lessons[0].id;
  // TODO fix me
  //@ts-ignore
  const topicId = topicID
    ? topicID
    : (program &&
        program?.lessons &&
        program?.lessons?.length > 0 &&
        program?.lessons[0] &&
        program?.lessons[0]?.topics &&
        program?.lessons[0]?.topics[0]?.id) ||
      0;

  const { sendProgress, getNextPrevTopic } = useContext(EscolaLMSContext);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [lessonId, topicId]);

  const lesson = useMemo(
    () => program.lessons.find((lesson) => lesson.id === Number(lessonId)),
    [program, lessonId]
  );

  const topic: API.TopicBase | undefined = useMemo(
    () =>
      lesson &&
      lesson.topics &&
      lesson.topics.find((topic) => topic.id === Number(topicId)),
    [lesson, topicId]
  );

  const onNextTopic = useCallback(() => {
    program.id &&
      sendProgress(program.id, [{ topic_id: Number(topicId), status: 1 }]).then(
        () => {
          const nextTopic = getNextPrevTopic(Number(topicId));

          nextTopic &&
            push(
              `/kurs/${program.id}/${nextTopic.lesson_id}/${nextTopic.id}`,
              null
            );
        }
      );
  }, [topicId, program, push, getNextPrevTopic, sendProgress]);

  useEffect(() => {
    // if last topic send progress

    if (!getNextPrevTopic(Number(topicId))) {
      sendProgress(program.id, [{ topic_id: Number(topicId), status: 1 }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicId, program]);

  const columnWidth =
    lesson &&
    lesson.summary &&
    fixContentForMarkdown(`${lesson.summary}`) !== "" &&
    topic &&
    topic.summary &&
    fixContentForMarkdown(`${topic.summary}`) !== ""
      ? 6
      : 12;

  return (
    <React.Fragment>
      <div className="container-fluid course-program">
        <div className="course-program-wrapper">
          <div className="course-program-player">
            <div className="course-program-player-content">
              <h2>{topic?.title}</h2>

              {topic &&
                topic.introduction &&
                fixContentForMarkdown(`${topic.introduction}`) !== "" && (
                  <div className="container-md">
                    <ReactMarkdownReader>
                      {topic.introduction}
                    </ReactMarkdownReader>
                  </div>
                )}

              <div
                className="course-program-player-content__wrapper"
                style={{
                  ...((topic?.json?.wrapperStyle as object) || {}),
                }}
              >
                <CourseProgramContent
                  lessonId={Number(lesson?.id)}
                  topicId={topic && Number(topic.id)}
                  setIsDisabledNextTopicButton={setIsDisabledNextTopicButton}
                />
              </div>
            </div>

            {topic?.description &&
              fixContentForMarkdown(topic.description) !== "" && (
                <div className="container-md">
                  <ReactMarkdownReader>{topic.description}</ReactMarkdownReader>
                </div>
              )}
            <div className="row">
              {lesson &&
                lesson.summary &&
                fixContentForMarkdown(`${lesson.summary}`) !== "" && (
                  <div
                    className={`col-lg-${columnWidth} col-md-${columnWidth} col-sm-12`}
                  >
                    <div className="course-program-summary">
                      <div className="container-md">
                        <ReactMarkdownReader>
                          {lesson.summary}
                        </ReactMarkdownReader>
                      </div>
                    </div>
                  </div>
                )}
              {topic &&
                topic.summary &&
                fixContentForMarkdown(`${topic.summary}`) !== "" && (
                  <div
                    className={`col-lg-${columnWidth} col-md-${columnWidth} col-sm-12`}
                  >
                    <div className="course-program-summary">
                      <div className="container-md">
                        <ReactMarkdownReader>
                          {topic.summary}
                        </ReactMarkdownReader>
                      </div>
                    </div>
                  </div>
                )}
            </div>
            {getNextPrevTopic(Number(topicId)) && (
              <div className="course-program-player-next">
                <button
                  disabled={
                    topic && topic.can_skip ? false : isDisabledNextTopicButton
                  }
                  className={`default-btn`}
                  onClick={onNextTopic}
                >
                  <div className="course-program-player-next-button__wrapper">
                    Następna lekcja
                    <ArrowIcon className="course-program-player-next-button__icon" />
                  </div>
                  <span></span>
                </button>
              </div>
            )}
          </div>
          <CourseProgramList
            course={program}
            lessonId={Number(lessonId)}
            topicId={Number(topicId)}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CourseProgramLessons;
