import React, { useContext, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import PageBanner from "../../components/Common/PageBanner";
import Layout from "../../components/_App/Layout";
import routes from "../../components/Routes/routes";

import "./index.scss";
import WhiteWrapper from "../../components/_App/WhiteWrapper";
import ContentWrapper from "../../components/_App/ContentWrapper";
import ReactMarkdownReader from "../../components/ReactMarkdown/ReactMarkdownReader";
import usePrevious from "../../hooks/usePrevious";
import Preloader from "../../components/Preloader";

const Index = () => {
  let { slug } = useParams<{ slug: string }>();
  const { fetchPage, page } = useContext(EscolaLMSContext);

  const prevSlug = usePrevious(slug)

  useEffect(() => {
    if (
        slug &&
        !page.loading &&
        (
            (!page.value && !page.error) ||
            (page.value && page.value?.slug !== slug) ||
            (page.error && !prevSlug)
        )
    ) {
      fetchPage(String(slug)).then(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, page]);

  // !page.value && !page.error - when very first load (empty state)
  // page.value && page.value?.slug !== slug - when prev static page was different
  // page.error && !prevSlug - when we return to static-page after error on static page (ex. when prev static page not exist)

  if (page.loading || ((!page.value && !page.error) || (page.value && page.value?.slug !== slug) || (page.error && !prevSlug))) {
    return (
        <Layout>
          <Preloader />
        </Layout>
    )
  }

  if (!page.loading && page.error && !page.error.success) {
    return <Redirect to={routes.notFound} />;
  }

  if (page && page.value) {
    return (
      <Layout>
        <div className="static-page">
          <PageBanner
            className="page-title-area--without-breadcrumbs"
            pageTitle={page?.value?.title}
            homePageUrl="/"
            homePageText="Home"
            activePageText={page?.value?.title}
          />

          <ContentWrapper className="static-page__content">
            <WhiteWrapper className="white-wrapper--with-big-padding">
              <ReactMarkdownReader>{page?.value?.content}</ReactMarkdownReader>
            </WhiteWrapper>
          </ContentWrapper>
        </div>
      </Layout>
    );
  }

  return <React.Fragment />;
};

export default Index;
