import Layout from "../../components/_App/Layout";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ProfileCourses from "../../components/Profile/ProfileCourses";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import "./index.scss";

const MyCourses = () => {
  const { user } = useContext(EscolaLMSContext);
  const history = useHistory();

  useEffect(() => {
    if (!user.value) {
      history.push("/");
    }
  }, [user, history]);

  return (
    <Layout>
      <React.Fragment>
        <ProfileCourses />
      </React.Fragment>
    </Layout>
  );
};

export default MyCourses;
