import React, { useContext } from "react";

import { CoursesContext } from "./CoursesContext";
import CourseCard from "../../components/CourseCard";
import Pagination from "../../components/Pagination";
import WhiteWrapper from "../../components/_App/WhiteWrapper";
import LoaderComponent from "../Loader";
import { useLocation } from "react-router-dom";

// enum Order {
//   ASC = "ASC",
//   DESC = "DESC",
// }

const CoursesCollection: React.FC<{ className?: string; title?: string }> = ({
  className = "",
  title = "Dostępne materiały szkoleniowe",
}) => {
  const { setParams, courses } = useContext(CoursesContext);

  const location = useLocation();

  if (!courses.list || !courses.list.data?.length) {
    return (
      <WhiteWrapper className={className}>
        <p>Brak materiałów szkoleniowych</p>
      </WhiteWrapper>
    );
  }

  return (
    <React.Fragment>
      {courses.loading ? (
        <WhiteWrapper className="loader">
          <LoaderComponent width={50} height={50} />
        </WhiteWrapper>
      ) : (
        <WhiteWrapper className={className}>
          <div className="escolalms-grid-sorting row align-items-center">
            <div className="col-lg-8 col-md-6 result-count">
              <h2>{title}</h2>
            </div>

            <div className="col-lg-4 col-md-6 ordering">
              {/* <div className="select-box">
            <select
              className="app-input"
              onChange={(e) => {
                const [order_by, order] = e.target.value.split("|");
                // @ts-ignore // TODO: fix any
                setParams((prevParams) => ({
                  ...prevParams,
                  order_by,
                  order: order as Order,
                  free: onlyFree ? true : prevParams.free,
                  per_page: 12,
                }));
              }}
            >
              <option disabled>Sortuj według</option>
              <option value="title|ASC">Tytuł</option>
              <option value="created_at|ASC">Data dodania</option>
            </select>
          </div> */}
            </div>
          </div>

          <div className="row">
            {
              // @ts-ignore // TODO: fix any
              courses.list.data.map((course) => (
                <div className="col-lg-4 col-md-6 " key={course.id}>
                  <CourseCard
                    course={course}
                    minHeight
                    location={location.pathname.split("/")[1] || "rodzice"}
                  />
                </div>
              ))
            }

            {courses.list.meta.total > courses.list.meta.per_page && (
              <Pagination
                total={courses.list.meta.total}
                perPage={courses.list.meta.per_page}
                currentPage={courses.list.meta.current_page}
                onPage={(i) =>
                  // @ts-ignore //
                  setParams((prevParams) => ({
                    ...prevParams,
                    page: i,
                    per_page: 12,
                    // free: true,
                  }))
                }
              />
            )}
          </div>
        </WhiteWrapper>
      )}
    </React.Fragment>
  );
};

export default CoursesCollection;
