const routeRoutes = {
  home: "/",
  course: "/kursy/:id",
  courseProgram: "/kurs/:id/:lessonID?/:topicID?",
  authentication: "/uwierzytelnienie",
  courses: "/nauczyciele",
  parents: "/rodzice",
  fus: "/fus",
  page: "/:slug",
  myProfile: "/moj-profil",
  myCourses: "/moje-kursy",
  tutors: "/autorzy",
  tutor: "/autor/:id",
  preview: "/:type/:id/:lessonID?/:topicID?",
  pages: "/strony",
  faq: "/faq",
  contact: "/kontakt",
  privacyPolicy: "/polityka-prywatnosci",
  emailVerified: "/email-verified",
  emailVerifiedPL: "/weryfikacja-email",
  notFound: "/404",
};

export default routeRoutes;
