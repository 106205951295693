import React, { FC } from "react";
import "./index.scss";
import { Field } from "formik";

interface Props {
  id?: number | string;
  label: string;
  name: string;
}

const Checkbox: FC<Props> = ({ id, label, name }) => {
  return (
    <>
      <label className="checkbox__container">
        {label}

        <Field name={name} value={`${id ? id : label}`} type="checkbox" />
        <span className="checkbox__checkmark"></span>
      </label>
    </>
  );
};

export default Checkbox;
