import React, { useState, useContext, useEffect } from "react";
import { Modal, Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Input from "../../components/Input";
import baseUrl from "../../utils/baseUrl";
import { Formik, Form, ErrorMessage } from "formik";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { FormState } from "./types";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import routes from "../Routes/routes";
import "./index.scss";
import "antd/dist/antd.css";

const LoginModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [state, setState] = React.useState<FormState>({ state: "input" });
  const { login, forgot, user } = useContext(EscolaLMSContext);
  const history = useHistory();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleExit = () => {
    setIsModalVisible(false);
    setForgotPassword(false);
  };

  useEffect(() => {
    if (user.error) {
      setState({
        state: "error",
        message:
          // @ts-ignore
          user.error?.data?.message ||
          user.error?.message ||
          "Coś poszło nie tak, skontaktuj się z administratorem",
        error: user.error?.errors
          ? Object.keys(user.error.errors)
              .map((key) => `${user.error?.errors[key]}`)
              .join("\r\n")
          : null,
      });
    }
  }, [user]);

  //console.log("vvv", user);

  return (
    <>
      <button
        className="app-button app-button-dark app-button-long app-button--register"
        onClick={showModal}
      >
        Zaloguj się <span className="rollover"></span>
      </button>
      <Modal
        title=""
        visible={isModalVisible}
        onCancel={handleExit}
        bodyStyle={{ backgroundColor: "white" }}
        footer={
          <div className="login-modal__footer-wrapper">
            <h1>Nie posiadasz konta?</h1>
            <button
              className="app-button login-modal__footer-button"
              onClick={() => {
                handleExit();
                history.push(routes.authentication);
              }}
            >
              Przejdź do formularza rejestracji
              <span className="rollover"></span>
            </button>
          </div>
        }
      >
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("E-mail jest nieprawidłowy")
              .required("Wymagany jest adres e-mail"),
            password: Yup.string().required("Hasło jest wymagane"),
          })}
          onSubmit={(fields) => {
            setState({
              state: "loading",
            });
            forgotPassword
              ? forgot({
                  email: fields.email,
                  return_url: `${baseUrl}/reset-password`,
                })
                  .then(() => {
                    setState({
                      state: "success",
                      message:
                        "Wysyłamy wiadomość e-mail z prośbą o zresetowanie hasła",
                    });
                  })
                  .catch((error: any) => {
                    setState({
                      state: "error",
                      message:
                        error?.data?.message ||
                        "Coś poszło nie tak, skontaktuj się z administratorem",
                      error: error?.data?.errors
                        ? Object.keys(error.data.errors)
                            .map((key) => `${error.data.errors[key]}`)
                            .join("\r\n")
                        : null,
                    });
                  })
              : login(fields)
                  .then((v) => {
                    setState({ state: "input" });
                  })
                  .catch((error: any) => {
                    console.log("eee", error);
                    setState({
                      state: "error",
                      message:
                        error?.data?.message ||
                        "Coś poszło nie tak, skontaktuj się z administratorem",
                      error: error?.data?.errors
                        ? Object.keys(error.data.errors)
                            .map((key) => `${error.data.errors[key]}`)
                            .join("\r\n")
                        : null,
                    });
                  });
          }}
          render={({ errors, touched }) => (
            <Form className="login-modal__form">
              <h1 className="login-modal__header">
                {forgotPassword ? "Przypomnij hasło" : "Zaloguj się"}
              </h1>
              <div className="form-group">
                <Input
                  name="email"
                  label="Adres e-mail"
                  isFormik
                  errors={errors.email}
                  touched={touched.email}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              {!forgotPassword && (
                <div className="form-group">
                  <Input
                    name="password"
                    label="Hasło"
                    type="password"
                    isFormik
                    errors={errors.password}
                    touched={touched.password}
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              )}
              <p
                className="login-modal__forgot-password"
                onClick={() => setForgotPassword((prev) => !prev)}
              >
                {forgotPassword ? "Powrót do logowania" : "Zapomniałem hasła"}
              </p>
              {state.state === "error" && (
                <Alert type="error" message={state.error || state.message} />
              )}
              <div className="form-group login-modal__button">
                <button className="app-button" type="submit">
                  {forgotPassword ? "Wyślij" : "Zaloguj"}
                  {state.state === "loading" && <LoadingOutlined spin />}
                  <span className="rollover"></span>
                </button>
              </div>
            </Form>
          )}
        />
      </Modal>
    </>
  );
};

export default LoginModal;
