import React, { useContext } from "react";

import CoursesSidebar from "../../components/Courses/CoursesSidebar";
import Layout from "../../components/_App/Layout";
import WhiteWrapper from "../../components/_App/WhiteWrapper";
import CoursesCollection from "../../components/Courses/CoursesCollection";
import CoursesProvider from "../../components/Courses/CoursesProvider";
import { CoursesContext } from "../../components/Courses/CoursesContext";
import "./index.scss";
import { useLocation } from "react-router-dom";
import routes from "../../components/Routes/routes";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";

const CoursesRightSidebar = () => {
  const { user } = useContext(EscolaLMSContext);
  const location = useLocation();

  const forTutorTabs = location.pathname === routes.courses;
  const renderMessage = forTutorTabs && !user.value;
  const isFusPage = location.pathname === routes.fus;

  return (
    <Layout>
      <CoursesProvider onlyFree={true}>
        <div className="courses-area escolalms-nav">
          <div className="course-container">
            <div className="course-wrapper">
              <div className="row">
                <div
                  className={`col-lg-${
                    forTutorTabs ? "9" : "12"
                  } col-md-12 order-1 order-md-0`}
                >
                  {renderMessage ? (
                    <WhiteWrapper>
                      <p>Aby zobaczyć treści zaloguj się.</p>
                    </WhiteWrapper>
                  ) : (
                    <CoursesCollection
                      title={
                        isFusPage
                          ? "Test fundamentalnych umiejętności ruchowych w sporcie"
                          : "Dostępne materiały szkoleniowe"
                      }
                    />
                  )}
                </div>
                {forTutorTabs && (
                  <div className="col-lg-3 col-md-12 col-xs-3 order-0 order-md-1">
                    <WhiteWrapper>
                      <CoursesContext.Consumer>
                        {({ params, setParams }) => (
                          <CoursesSidebar
                            multiple
                            params={params}
                            onTag={(tag) => {
                              // @ts-ignore
                              setParams((prevParams) => ({
                                ...prevParams,
                                page: 1,
                                per_page: 12,
                                // free: true,
                                // FIXME, tag can be either string or string[]
                                // @ts-ignore
                                tag: Array.isArray(tag)
                                  ? tag.map((tag) => tag.title)
                                  : undefined,
                              }));
                            }}
                            onSearch={(term) =>
                              // @ts-ignore TODO
                              setParams((prevParams) => ({
                                ...prevParams,
                                page: 1,
                                per_page: 12,
                                title: term ? term : undefined,
                              }))
                            }
                            onCategory={(category_id) => {
                              // @ts-ignore TODO
                              setParams((prevParams) => ({
                                ...prevParams,
                                page: 1,
                                per_page: 12,
                                category_id: category_id
                                  ? Number(category_id)
                                  : undefined,
                              }));
                            }}
                            onTutor={(tutor_id) => {
                              // @ts-ignore TODO
                              setParams((prevParams) => ({
                                ...prevParams,
                                page: 1,
                                per_page: 12,
                                author_id: tutor_id
                                  ? Number(tutor_id)
                                  : undefined,
                              }));
                            }}
                          />
                        )}
                      </CoursesContext.Consumer>
                    </WhiteWrapper>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </CoursesProvider>
    </Layout>
  );
};

export default CoursesRightSidebar;
