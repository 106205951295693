import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
// import routes from "../../components/Routes/routes";

import CourseProgramLessons from "../../components/Course/CourseProgramLessons";
import Layout from "../../components/_App/Layout";
import WhiteWrapper from "../../components/_App/WhiteWrapper";
import LoaderComponent from "../../components/Loader";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
// import ErrorBox from "../../components/ErrorBox/ErrorBox";
// import WhiteWrapper from "../../components/_App/WhiteWrapper";
// import LoaderComponent from "../../components/Loader";

const CourseProgramScorm: React.FC<{ program: API.CourseProgram }> = ({
  program,
}) => {
  const sco = program?.scorm?.scos?.find((sco) => sco?.entry_url !== undefined);
  const uuid = sco?.uuid;
  const iframeRef = useRef<HTMLIFrameElement>();
  const [height, setHeight] = useState(null);
  const headerAndFooterHeight = 610;
  const { apiUrl } = useContext(EscolaLMSContext);

  useEffect(() => {
    if (iframeRef.current) {
      // @ts-ignore FIXME TODO
      setHeight(iframeRef.current?.contentWindow?.document?.body?.scrollHeight);
    }
  }, [iframeRef]);

  if (!sco && !uuid) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <div className="container-fluid course-program">
        <div
          className="course-program-player scorm"
          style={{
            height: `${headerAndFooterHeight + (height || 0)}px`,
          }}
        >
          <iframe
            title={"scorm-player"}
            // TODO FIXME
            //@ts-ignore
            ref={iframeRef}
            src={`${apiUrl}/api/scorm/play/${uuid}`}
            scrolling="no"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const CourseProgram = () => {
  const { id } = useParams<{ id: string }>();
  const { program, fetchProgram, fetchProgress } = useContext(EscolaLMSContext);

  useEffect(() => {
    if (id) {
      fetchProgram(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (program.loading) {
    return (
      <Layout>
        <WhiteWrapper className="loader">
          <LoaderComponent width={50} height={50} />
        </WhiteWrapper>
      </Layout>
    );
  }

  // if (program.error) {
  //   return (
  //     <Layout>
  //       <ErrorBox>
  //         <p>{program.error.message || program.error.error}</p>
  //         <hr />
  //       </ErrorBox>
  //     </Layout>
  //   );
  // }

  if (
    program.value &&
    program?.value?.scorm?.id &&
    program.value.id === Number(id)
  ) {
    return (
      <Layout>
        <CourseProgramScorm program={program.value} />
      </Layout>
    );
  }
  if (
    program.value &&
    program.value.lessons &&
    program.value.lessons.length &&
    program.value.id === Number(id)
  ) {
    return (
      <Layout>
        <CourseProgramLessons program={program.value} />
      </Layout>
    );
  }

  return <></>;
};

export default CourseProgram;
