import React from "react";
import ClockIcon from "../../../images/awf/clock.svg";
import "./index.scss";

const CourseDuration:React.FC<{duration:string | number}> = ({ duration }) => {
  return (
    <div className="course-duration">
      {duration && (
        <React.Fragment>
          <img src={ClockIcon} alt="clock icon" />
          {duration}
        </React.Fragment>
      )}
    </div>
  );
};

export default CourseDuration;
