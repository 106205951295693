import React, { useState, useContext, useEffect } from "react";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import Markdown from "markdown-to-jsx";
import Layout from "../../components/_App/Layout";
import defaultAvatar from "../../images/default-avatar.png";
import ArrowIcon from "../../icons/Arrow";
import "./index.scss";
import { API } from "@escolalms/sdk/lib";
import Image from "@escolalms/sdk/lib/react/components/Image";

const Tutors = () => {
  const { tutors, fetchTutors } = useContext(EscolaLMSContext);

  useEffect(() => {
    fetchTutors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [state, setState] = useState<string | number>("");

  return (
    <Layout>
      <div className="authors-container">
        <div className="authors-wrapper">
          <h1>Autorzy materiałów szkoleniowych</h1>
          <div className="authors-card">
            {tutors &&
              tutors.list?.map((element: API.UserItem) => {
                return (
                  <React.Fragment key={element.id}>
                    <button
                      onClick={() => {
                        if (state === element.id) {
                          setState("");
                        } else {
                          setState(element.id);
                        }
                      }}
                      className="authors-card__content"
                    >
                      <div className="authors-card__info">
                        {element.path_avatar ? (
                          <Image
                            className="authors-card__img"
                            alt={element.first_name + ' ' + element.last_name}
                            path={element?.path_avatar || ''}
                            srcSizes={[380, 380 * 2]}
                          />
                        ) : (
                          <img
                            src={defaultAvatar}
                            alt={element.first_name + ' ' + element.last_name}
                            className="authors-card__img"
                          />
                        )
                        }
                        <div className="authors-card__text">
                          <span className="authors-card__name">
                            {element.first_name + ' ' + element.last_name}
                          </span>
                          <span>{element.email}</span>
                        </div>
                      </div>
                      <div className="authors-card__icon-container">
                        <div className="authors-card__icon-wrapper">
                          <ArrowIcon
                            className={`authors-card__icon ${
                              state === element.id
                                ? "authors-card__icon--active"
                                : ""
                            }`}
                            // TODO fix this
                            //@ts-ignore
                            fill="white"
                          />
                        </div>
                      </div>
                    </button>
                    {state === element.id && (
                      <Markdown className="authors-card__bio">
                        {element.bio ? element.bio : ""}
                      </Markdown>
                    )}
                  </React.Fragment>
                );
              })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Tutors;
