import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { API } from "@escolalms/sdk/lib";
import WhiteWrapper from "../_App/WhiteWrapper";
import LoaderComponent from "../Loader";
import StartedCourses from "./StartedCourses";
import AvailableCourses from "./AvailableCourses";
import FinishedCourses from "./FinishedCourses";
import TagCloud from "../Common/TagCloud";
import "./index.scss";

const ProfileCourses = () => {
  const { progress, fetchProgress } = useContext(EscolaLMSContext);
  const [activeTagTitle, setActiveTagTitle] = useState<string>("");

  useEffect(() => {
    fetchProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleTag = useCallback(
    (tag: API.Tag) => {
      setActiveTagTitle(activeTagTitle === tag.title ? "" : tag.title);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTagTitle]
  );

  const courses = useMemo(() => {
    return (progress?.value || []).filter(
      // @ts-ignore // TODO: add tags to sdk
      (course: API.CourseProgressItem & { tags: API.Tag[] }) =>
        activeTagTitle === "" ||
        course.tags.some((t: API.Tag) => t.title === activeTagTitle)
    );
  }, [progress, activeTagTitle]);

  const startedCourses = useMemo(() => {
    return courses.filter(
      (course: API.CourseProgressItem) =>
        (course?.progress?.length || 0) > 0 &&
        (course?.course?.base_price || 0) > 0 &&
        (course?.total_spent_time || 0) > 0 &&
        !course.finish_date
    );
  }, [courses]);

  const finishedCourses = useMemo(() => {
    return courses.filter(
      (course: API.CourseProgressItem) => course.finish_date
    );
  }, [courses]);

  const availableCourses = useMemo(() => {
    return courses.filter(
      (course: API.CourseProgressItem) =>
        (course?.course?.base_price || 0) > 0 &&
        (course?.progress?.length || 0) > 0 &&
        (course?.total_spent_time || 0) === 0
    );
  }, [courses]);

  const noCourses = useMemo(() => {
    return (
      !startedCourses.length &&
      !finishedCourses.length &&
      !availableCourses.length
    );
  }, [startedCourses, finishedCourses, availableCourses]);

  return (
    <div className="my-courses-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-9 col-md-12 order-1 order-lg-0">
            {progress.loading ? (
              <div className="profile-courses">
                <WhiteWrapper className="loader">
                  <LoaderComponent width={50} height={50} />
                </WhiteWrapper>
              </div>
            ) : (
              <React.Fragment>
                {noCourses ? (
                  <div className="profile-courses no-data">
                    <WhiteWrapper>
                      <p>Nie masz kursów</p>
                    </WhiteWrapper>
                  </div>
                ) : (
                  <React.Fragment>
                    <AvailableCourses availableCourses={availableCourses} />

                    <StartedCourses startedCourses={startedCourses} />

                    <FinishedCourses finishedCourses={finishedCourses} />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>

          <div className="col-lg-3 col-md-12 col-xs-3 order-0 order-lg-1 tags-wrapper">
            <WhiteWrapper>
              <TagCloud onClick={toggleTag} activeTagTitle={activeTagTitle} />
            </WhiteWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCourses;
