import React from "react";
import { Link } from "react-router-dom";

const PageBanner: React.FC<{
  pageTitle: string;
  homePageUrl: string;
  homePageText: string;
  activePageText: string;
  className?: string;
}> = ({ pageTitle, homePageUrl, homePageText, activePageText, className = ''}) => {
  return (
    <div className={`page-title-area ${className}`}>
      <div className="container">
        <div className="page-title-area__content">
          <ul className="breadcrumbs">
            <li className="breadcrumbs__item">
              <Link className="breadcrumbs__link" to={homePageUrl}>{homePageText}</Link>
            </li>
            <li className="breadcrumbs__item active">{activePageText}</li>
          </ul>

          <h1 className="page-title-area__title">{pageTitle}</h1>
        </div>
      </div>
    </div>
  );
};

export default PageBanner;
