import React from "react";
import "./ContentWrapper.scss";

const ContentWrapper: React.FC<{ children: React.ReactNode; className: string }> = ({
  children,
  className = "",
}) => {
  return <div className={`content-wrapper ${className}`}>{children}</div>;
};

export default ContentWrapper;
