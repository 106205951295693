import React from "react";
import { Link } from "react-router-dom";
import Image from "@escolalms/sdk/lib/react/components/Image";
import { API } from "@escolalms/sdk/lib";

const UserCourse: React.FC<{
  course: API.Course;
  progress: number;
  categories: API.CategoryListItem[];
}> = ({ course, progress = 0, categories }) => {
  return (
    <div className="single-courses-box">
      <div className="courses-wrapper">
        <div className="courses-image">
          <Link className="d-block image" to={`/kurs/${course.id}`}>
            {course.image_path && (
              <Image path={course.image_path} srcSizes={[160, 106]} />
            )}
          </Link>
        </div>

        <div className="courses-content">
          <div className="courses-categories">
            {categories &&
              categories.map((category) => (
                <Link
                  to={`/materialy-szkoleniowe?free=true&category_id=${category.id}`}
                >
                  {category.name}
                </Link>
              ))}
          </div>

          <h3>
            <Link to={`/kurs/${course.id}`}>{course.title}</Link>
          </h3>
          <div className="courses-progress">
            <span>Ukończono</span>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${progress}%` }}
              />
              <div
                className="circle-wrapper"
                style={{ left: `${progress - 1}%`, top: "-3px" }}
              >
                <div className="circle" />
                <span> {progress}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="courses-continue">
        <Link to={`/kurs/${course.id}`}>Kontynuuj</Link>
      </div>
    </div>
  );
};

export default UserCourse;
