/** https://codesandbox.io/s/wizardly-breeze-8ty87?file=/src/useWidth.js:572-580 **/

import { useState, useEffect, useCallback, RefObject } from "react";

function useWidth(elementRef: RefObject<HTMLElement>) {
  const [width, setWidth] = useState<number|null>(null);

  const updateWidth = useCallback(() => {
    if (elementRef && elementRef.current) {
      const { width } = elementRef.current.getBoundingClientRect();
      setWidth(width);
    }
  }, [elementRef]);

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [updateWidth]);

  return width;
}

export default useWidth;
