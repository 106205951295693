import React from "react";
import ReactDOM from "react-dom";
import { EscolaLMSContextProvider } from "@escolalms/sdk/lib/react/context";
import App from "./App";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./i18n";
import { createRoot } from "react-dom/client";
const container = document.getElementById("root");

Sentry.init({
  dsn: "https://e1da831c851a4c97aed3239a93e6db0d@sentry.etd24.pl/58",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

container &&
  createRoot(container).render(
    <React.StrictMode>
      {process.env.REACT_APP_PUBLIC_API_URL ? (
        <EscolaLMSContextProvider apiUrl={process.env.REACT_APP_PUBLIC_API_URL}>
          <App />
        </EscolaLMSContextProvider>
      ) : (
        <pre>error `process.env.REACT_APP_PUBLIC_API_URL` not set</pre>
      )}
    </React.StrictMode>
  );
