import React from "react";
import Loader from "react-loader-spinner";
import "./index.scss";

const LoaderComponent: React.FC<{ width: number; height: number }> = ({
  width,
  height,
}) => {
  return (
    <div className="loader-wrapper">
      <Loader type="ThreeDots" color="#003e70" width={width} height={height} />
    </div>
  );
};

export default LoaderComponent;
