import React, { useState, useContext } from "react";
import { Modal, Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Input from "../../components/Input";
import { Formik, Form, ErrorMessage } from "formik";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { FormState } from "./types";
import * as Yup from "yup";
import "./index.scss";
import "antd/dist/antd.css";

const LoginModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [state, setState] = React.useState<FormState>({ state: "input" });
  const { user, updateProfile } = useContext(EscolaLMSContext);

  const showModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <a href="!#href" className="nav-link" onClick={(e) => showModal(e)}>
        Mój profil
      </a>
      <Modal
        title=""
        visible={isModalVisible}
        onCancel={handleCancel}
        bodyStyle={{ backgroundColor: "white" }}
        footer={null}
      >
        <Formik
          initialValues={{
            first_name: user?.value?.first_name || "",
            last_name: user?.value?.last_name || "",
          }}
          validationSchema={Yup.object().shape({
            first_name: Yup.string().required("Imię jest obowiązkowe"),
            last_name: Yup.string().required("Nazwisko jest obowiązkowe"),
          })}
          onSubmit={(fields) => {
            setState({
              state: "loading",
            });
            if (user.value) {
              updateProfile({
                ...user.value,
                ...fields,
                // TODO FIX THIS
                // name: `${fields.first_name} ${fields.last_name}`,
                // bio: user.value.bio !== null ? user.value.bio : " ",
              })
                .then(() => {
                  setState({ state: "input" });
                })
                .catch((error: any) => {
                  setState({
                    state: "error",
                    message:
                      error?.data?.message ||
                      "Coś poszło nie tak, skontaktuj się z administratorem",
                    error: error?.data?.errors
                      ? Object.keys(error.data.errors)
                          .map((key) => `${error.data.errors[key]}`)
                          .join("\r\n")
                      : null,
                  });
                });
            }
          }}
          render={({ errors, touched }) => (
            <Form className="login-modal__form">
              <h1 className="login-modal__header">Aktualizacja danych</h1>
              <div className="form-group">
                <Input
                  name="first_name"
                  label="Imię"
                  isFormik
                  errors={errors.first_name}
                  touched={touched.first_name}
                />
                <ErrorMessage
                  name="first_name"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <Input
                  name="last_name"
                  label="Nazwisko"
                  isFormik
                  errors={errors.last_name}
                  touched={touched.last_name}
                />
                <ErrorMessage
                  name="last_name"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              {state.state === "error" && (
                <Alert type="error" message={state.error || state.message} />
              )}

              <div className="form-group login-modal__button">
                <button className="app-button" type="submit">
                  Zaktualizuj
                  {state.state === "loading" && <LoadingOutlined spin />}
                  <span className="rollover"></span>
                </button>
              </div>
            </Form>
          )}
        />
      </Modal>
    </>
  );
};

export default LoginModal;
