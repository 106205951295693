import { API } from "@escolalms/sdk/lib";
import React, { useContext } from "react";
import LmsTag from "../LmsTag";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import useWordCut from "../../../hooks/useWordCut";
import "./index.scss";

const TagCloud: React.FC<{
  activeTagTitle?: string;
  onClick?: (tag: API.Tag) => void;
  className?: string;
}> = ({ onClick, className = "", activeTagTitle = "" }) => {
  const { uniqueTags } = useContext(EscolaLMSContext);
  const tags = useWordCut<API.Tag>(
    uniqueTags.list ? uniqueTags.list : [],
    "title",
    [
      "Klasy 1-3",
      "Klasy 4-8",
      "Klasy 8+",
      // "Izolacja",
      "Psychologia",
      // "Hipokinezja",
      "Kurs",
      "Warsztat",
    ]
  ).filter((tag) => tag.title !== "Warsztat");

  return (
    <div className={`tag-cloud ${className}`}>
      <h3 className="tag-cloud__title">Popularne tematy</h3>
      <div className="tag-cloud__list">
        {tags?.map((tag: API.Tag) => (
          <LmsTag
            key={tag.title}
            tag={tag}
            isActive={activeTagTitle.includes(tag.title)}
            onClick={onClick}
          />
        ))}
      </div>
    </div>
  );
};

export default TagCloud;
