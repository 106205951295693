import React, { useContext, useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";

import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { API } from "@escolalms/sdk/lib";
import Layout from "../../../components/_App/Layout";

import CourseProgramLessonsPreview from "../../../components/Course/CourseProgramLessonsPreview";
import ErrorBox from "../../../components/ErrorBox/ErrorBox";
import LoaderComponent from "../../../components/Loader";
import WhiteWrapper from "../../../components/_App/WhiteWrapper";

const CourseProgramScorm: React.FC<{ program: API.CourseProgram }> = ({
  program,
}) => {
  const sco = program?.scorm?.scos?.find((sco) => sco.entry_url !== undefined);
  const uuid = sco?.uuid;
  const iframeRef = useRef<HTMLIFrameElement>();
  const [height, setHeight] = useState(null);
  const headerAndFooterHeight = 610;
  const { apiUrl } = useContext(EscolaLMSContext);

  useEffect(() => {
    if (iframeRef.current) {
      // TODO FIXME
      //@ts-ignore
      setHeight(iframeRef.current?.contentWindow?.document?.body?.scrollHeight);
    }
  }, [iframeRef]);

  if (!sco && !uuid) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <div className="container-fluid course-program">
        <div
          className="course-program-player scorm"
          style={{
            height: `${headerAndFooterHeight + (height || 0)}px`,
          }}
        >
          <iframe
            title={"scrorm-player"}
            // TODO FIXME
            //@ts-ignore
            ref={iframeRef}
            src={`${apiUrl}/api/scorm/play/${uuid}`}
            scrolling="no"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const CourseProgram = () => {
  const { id } = useParams<{ id: string }>();
  const { program, fetchProgram } = useContext(EscolaLMSContext);

  useEffect(() => {
    fetchProgram(Number(id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // if (program.error) {
  //   return (
  //     <Layout>
  //       <ErrorBox>
  //         <p> {program.error.message || program.error.error}</p>
  //         <hr />
  //       </ErrorBox>
  //     </Layout>
  //   );
  // }
  if (program.loading) {
    return (
      <Layout>
        <WhiteWrapper className="loader">
          <LoaderComponent width={50} height={50} />
        </WhiteWrapper>
      </Layout>
    );
  }
  if (
    program.value &&
    program?.value?.scorm?.id &&
    program.value.id === Number(id)
  ) {
    return (
      <Layout>
        <CourseProgramScorm program={program.value} />
      </Layout>
    );
  }
  if (
    program.value &&
    program.value.lessons &&
    program.value.lessons.length &&
    program.value.id === Number(id)
  ) {
    return (
      <Layout>
        <CourseProgramLessonsPreview program={program.value} />;
      </Layout>
    );
  }

  return (
    <Layout>
      {/* <ErrorBox>
        <p>Kurs jest w trakcie edycji</p>
        <hr />
        <p className="mb-0">
          Zobacz inne{" "}
          <Link to="/materialy-szkoleniowe?free=true&per_page=12">kursy</Link>.
        </p>
      </ErrorBox> */}
    </Layout>
  );
};

export default CourseProgram;
