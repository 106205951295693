import React, { useContext, useEffect, useMemo, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { format } from "date-fns";
import Image from "@escolalms/sdk/lib/react/components/Image";
import { API } from "@escolalms/sdk/lib";
import Layout from "../../../components/_App/Layout";
import CourseProgramPreview from "../../../components/Course/CourseProgramPreview";
import WhiteWrapper from "../../../components/_App/WhiteWrapper";
import CourseDuration from "../../../components/CourseCard/CourseDuration";

import "./index.scss";
import ReactMarkdownReader from "../../../components/ReactMarkdown/ReactMarkdownReader";

export const CourseProgramList: React.FC<{
  program: API.Lesson[];
  onPreview?: (topic: API.Topic) => void;
}> = ({ program, onPreview = null }) => {
  return (
    <div className="courses-curriculum">
      {program.map((lesson, lesson_index) => {
        return (
          <React.Fragment key={lesson.id}>
            <h3 className="d-flex justify-content-between align-items-center">
              <span>
                <small>{lesson_index + 1}. </small> {lesson.title}
              </span>
              <div className="courses-meta">
                {lesson.duration && (
                  <span className="duration">{lesson.duration}</span>
                )}
              </div>
            </h3>
            <ul>
              {lesson &&
                lesson.topics &&
                lesson.topics.map((topic, topic_index) => {
                  return (
                    <li key={topic.id}>
                      <div className="d-flex justify-content-between align-items-center anchor">
                        <span className="courses-name">
                          <small>
                            {lesson_index + 1}.{topic_index + 1}{" "}
                          </small>
                          {topic.title}
                        </span>
                        <div className="courses-meta">
                          <span
                            className={`status ${
                              topic &&
                              topic?.topicable_type &&
                              topic?.topicable_type.split("\\").pop()
                            }`}
                          ></span>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const CoursePriceButton: React.FC<{ course?: API.Course }> = ({ course }) => {
  const { user, progress, fetchProgress } = useContext(EscolaLMSContext);

  const id = course?.id;

  useEffect(() => {
    user && user.value && fetchProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const userOwnThisCourse = useMemo(() => {
    return (
      progress.value &&
      progress.value.findIndex(
        (item: API.CourseProgressItem) => item.course.id === id
      ) !== -1
    );
  }, [progress, id]);

  return (
    <div className="courses-price">
      {userOwnThisCourse && (
        <Link to={`/course/${course?.id}`} className="startCourse">
          Rozpocznij kurs
        </Link>
      )}

      {course?.base_price === 0 && (
        <Link className="startCourse" to={`/courses/preview/${course.id}`}>
          Rozpocznij szkolenie
        </Link>
      )}
    </div>
  );
};

const SingleCourses = () => {
  const { id } = useParams<{ id: string }>();

  const { course, fetchCourse } = useContext(EscolaLMSContext);

  const [previewTopic, setPreviewTopic] = useState<API.Topic>();

  useEffect(() => {
    if (id) {
      fetchCourse(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (course.error) {
    return <pre>{course.error.message}</pre>;
  }

  return (
    <Layout>
      <React.Fragment>
        {previewTopic && (
          <CourseProgramPreview
            topic={previewTopic}
            onClose={() => {
              setPreviewTopic(undefined);
            }}
          />
        )}

        <div className="courses-details-area ptb-100 escolalms-nav">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <WhiteWrapper>
                  <div className="courses-details-header">
                    {course &&
                      course.value &&
                      course.value.categories &&
                      course.value.categories.length > 0 && (
                        <div>
                          {course.value.categories.map(
                            (
                              category: API.CategoryListItem | (number | string)
                            ) =>
                              category &&
                              typeof category === "object" &&
                              category.id ? (
                                <Link
                                  className="category"
                                  to={`/courses?category_id=${category.id}`}
                                  key={category.id}
                                >
                                  {category.name}
                                </Link>
                              ) : (
                                <React.Fragment />
                              )
                          )}
                        </div>
                      )}
                    <h2>{course?.value?.title}</h2>
                    <div className="course-meta">
                      {course?.value?.duration && (
                        <CourseDuration duration={course.value.duration} />
                      )}

                      {course?.value?.updated_at && (
                        <div className="course-meta__update">
                          <span>Aktualizacja </span>

                          {format(
                            new Date(String(course?.value?.updated_at)),
                            "dd/MM/yyyy"
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="courses-details-image-style-two text-center">
                    {course?.value?.image_path && (
                      <Image
                        path={course.value.image_path}
                        srcSizes={[790 * 0.5, 790, 2 * 790]}
                      />
                    )}
                  </div>

                  <div className="courses-details-desc-style-two">
                    {/* <h3>{t("Summary")}</h3>
                    <ReactMarkdownReader>{course.value.summary}</ReactMarkdownReader> */}

                    <ReactMarkdownReader>
                      {course?.value?.description || ""}
                    </ReactMarkdownReader>
                  </div>
                </WhiteWrapper>

                {course?.value?.author && (
                  <div className="courses-author">
                    <WhiteWrapper>
                      <div className="author-profile">
                        <Link to={`/tutors/${course?.value?.author.id}`}>
                          <div className="author-profile-title">
                            {course?.value?.author?.path_avatar && (
                              <Image
                                path={course?.value?.author?.path_avatar}
                                className="shadow-sm rounded-circle"
                                srcSizes={[73, 73 * 2]}
                              />
                            )}
                            <div className="author-profile-title-details">
                              <div className="author-profile-details">
                                <h3>Autor szkolenia</h3>
                                <h4>
                                  {course.value.author.first_name}{" "}
                                  {course.value.author.last_name}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </Link>
                        <div className="bio">
                          <ReactMarkdownReader>
                            {course.value.author?.bio || ""}
                          </ReactMarkdownReader>
                        </div>
                      </div>
                    </WhiteWrapper>
                  </div>
                )}
              </div>

              <div className="col-lg-4 col-md-12 program-wrapper">
                <WhiteWrapper>
                  <div className="program-heading">
                    <h3>Program</h3>
                  </div>
                </WhiteWrapper>
                {course?.value?.lessons && (
                  <WhiteWrapper>
                    <div className="courses-details-desc-style-two program">
                      <CourseProgramList
                        program={course?.value?.lessons}
                        onPreview={(topic) => setPreviewTopic(topic)}
                      />
                    </div>
                  </WhiteWrapper>
                )}
                <WhiteWrapper>
                  <div className="start-course">
                    <CoursePriceButton course={course?.value} />
                  </div>
                </WhiteWrapper>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </Layout>
  );
};

export default SingleCourses;
