import React, { FC } from "react";
import "./index.scss";
import { Field } from "formik";

interface Props {
  id?: number;
  label: string;
  name: string;
  value: string;
  disabled?: boolean;
}

const Radiobox: FC<Props> = ({ label, name, value, disabled = false }) => {
  return (
    <>
      <label className="radiobox__container">
        {label}
        <Field
          disabled={disabled}
          name={name}
          type="radio"
          value={`${value ? value : name}`}
        />
        <span
          className={`radiobox__checkmark${disabled ? "--disabled" : ""}`}
        ></span>
      </label>
    </>
  );
};

export default Radiobox;
