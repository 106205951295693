import React from "react";

import Banner from "../components/Banner";

import Layout from "../components/_App/Layout";
import CoursesProvider from "../components/Courses/CoursesProvider";
import CoursesCollection from "../components/Courses/CoursesCollection";

const Index = () => {
  return (
    <Layout>
      <React.Fragment>
        <Banner />

        <div className="container mt-50 mb-50">
          <div className="row">
            <CoursesProvider onlyFree={true}>
              <CoursesCollection className="full-width" />
            </CoursesProvider>
          </div>
        </div>
      </React.Fragment>
    </Layout>
  );
};

export default Index;
