import Logo from "../images/awf/logoawf.svg";
const Preloader = () => {
  return (
    <>
      <div className="inline-loader">
        <div className="loader">
          <div className="loadingio-spinner-reload">
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={Logo} role="presentation" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Preloader;
